.combination_boards {
  position: relative;
}

.combination_boards img {
  width: 100%;
}

.customize_options_header {
  text-align: center;
  font-weight: 500;
  font-size: 2.2rem;
  text-transform: uppercase;
}

.akronym_info {
  display: flex;
  justify-content: flex-end;
  margin-right: 3rem;
  font-size: 1.6rem;
}

.akronym_info ul {
  list-style: none;
}

/****************
* Media Queries *
*****************/
/* screen > 2000px */
@media (min-width: 125em) {
  .combination_boards {
    padding: 10rem 15rem;
  }
  .customize_options_header {
    font-size: 3.2rem;
  }
  .akronym_info {
    font-size: 2.2rem;
  }
}

/* screen > 3000px */
@media (min-width: 187.5em) {
  .customize_options_header {
    font-size: 5.6rem;
  }
  .akronym_info {
    font-size: 3.8rem;
  }
}

/* screen < 1600px */
@media (max-width: 100em) {
  .customize_options_header {
    font-size: 1.8rem;
  }
  .akronym_info {
    font-size: 1.4rem;
    margin-right: 2rem;
  }
}

/* screen < 1200px */
@media (max-width: 75em) {
  .akronym_info {
    font-size: 1.2rem;
    margin-right: 1rem;
  }
}

/* screen < 960px */
@media (max-width: 60em) {
  .customize_options_header {
    font-size: 1.6rem;
  }
  .akronym_info {
    font-size: 1rem;
    margin-right: 1rem;
  }
}
/* screen < 480px */
@media (max-width: 30em) {
  .customize_options_header {
    font-size: 1.4rem;
  }
}
