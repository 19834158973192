.customize_box {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2rem;
  margin-bottom: 10rem;
}

.customize_options_title {
  text-align: center;
  font-weight: 500;
  font-size: 2.6rem;
  text-transform: uppercase;
}

.customize_images {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  padding: 2rem;
}

.customize_images img {
  width: 100%;
}

.customize_images > div h4 {
  text-align: center;
  text-transform: uppercase;
  font-size: 1.6rem;
  font-weight: 500;
  color: rgb(76, 76, 76);
}

/****************
* Media Queries *
*****************/
/* screen > 2000px */
@media (min-width: 125em) {
  .customize_box {
    padding-top: 10rem;
    gap: 5rem;
  }
  .customize_options_title {
    font-size: 3.2rem;
  }
  .customize_images > div h4 {
    font-size: 2.2rem;
  }
}

/* screen > 3000px */
@media (min-width: 187.5em) {
  .customize_box {
    padding-top: 15rem;
    gap: 8rem;
  }
  .customize_options_title {
    font-size: 5.6rem;
  }
  .customize_images > div h4 {
    font-size: 3.2rem;
  }
}

/* screen < 1600px */
@media (max-width: 100em) {
  .customize_options_title {
    font-size: 1.8rem;
  }
  .customize_images > div h4 {
    font-size: 1.4rem;
  }
}

/* screen < 1200px */
@media (max-width: 75em) {
  .customize_images {
    padding: 0 1rem;
  }
  .customize_images > div h4 {
    font-size: 1.2rem;
  }
}

/* screen < 960px */
@media (max-width: 60em) {
  .customize_options_title {
    font-size: 1.6rem;
  }
  .customize_box {
    grid-template-columns: 1fr 1fr;
    column-gap: 2rem;
    gap: 4rem;
  }
  .customize_options:last-child {
    grid-column: 1/3;
    width: 50%;
    margin: 0 auto;
  }
  .customize_images > div h4 {
    font-size: 1rem;
  }
}

/* screen < 512px */
@media (max-width: 32em) {
  .customize_box {
    grid-template-columns: 1fr;
    gap: 4rem;
  }
  .customize_options {
    width: 85%;
    margin: 0 auto;
  }
  .customize_options:last-child {
    grid-column: 1/2;
    width: 85%;
  }
}

/* screen < 480px */
@media (max-width: 30em) {
  .customize_options_title {
    font-size: 1.4rem;
  }
}
