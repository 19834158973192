.customize_accessories_box {
  margin-bottom: 10rem;
}

.customize_accessories_title {
  text-align: center;
  font-weight: 500;
  font-size: 2.6rem;
  text-transform: uppercase;
}

.customize_accessories_options {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 6rem;
  padding: 2rem 6rem;
}

.customize_accessories_options > div {
  height: 22rem;
}

.customize_accessories_image {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.customize_accessories_image > h3 {
  text-align: center;
  text-transform: uppercase;
  font-size: 1.6rem;
  font-weight: 500;
  color: rgb(76, 76, 76);
}

.customize_accessories_options img {
  height: 100%;
}

/****************
* Media Queries *
*****************/
/* screen > 2000px */
@media (min-width: 125em) {
  .customize_accessories_title {
    font-size: 3.2rem;
  }
  .customize_accessories_options > div {
    height: 30rem;
  }
  .customize_accessories_image > h3 {
    font-size: 2.4rem;
  }
}

/* screen > 3000px */
@media (min-width: 187.5em) {
  .customize_accessories_title {
    font-size: 5.6rem;
  }
  .customize_accessories_options > div {
    height: 45rem;
  }
  .customize_accessories_image > h3 {
    font-size: 3.2rem;
  }
}

/* screen < 1600px */
@media (max-width: 100em) {
  .customize_accessories_title {
    font-size: 1.8rem;
  }
  .customize_accessories_options > div {
    height: 18rem;
  }
}

/* screen < 1200px */
@media (max-width: 75em) {
  .customize_accessories_options > div {
    height: 16rem;
  }
  .customize_accessories_image > h3 {
    font-size: 1.2rem;
  }
}

/* screen < 960px */
@media (max-width: 60em) {
  .customize_accessories_title {
    font-size: 1.6rem;
  }
  .customize_accessories_options {
    padding: 2rem 0;
  }
}

/* screen < 640px */
@media (max-width: 40em) {
  .customize_accessories_options {
    gap: 2rem;
  }
  .customize_accessories_options > div {
    height: 12rem;
  }
  .customize_accessories_image > h3 {
    font-size: 1rem;
  }
}

/* screen < 480px */
@media (max-width: 30em) {
  .customize_accessories_options {
    gap: 1rem;
  }
  .customize_accessories_options > div {
    height: 9rem;
  }
}
