.libraries_list_section {
  padding: 13rem 15rem 10rem;
  min-height: 90vh;
  font-family: 'Montserrat', sans-serif;
  background: linear-gradient(
    170deg,
    rgba(247, 241, 241, 1) 0%,
    rgba(208, 240, 241, 1) 75%,
    rgba(208, 247, 255, 1) 100%
  );
}

.libraries_title {
  text-align: center;
  font-size: 7.6rem;
  font-weight: 500;
  color: #1282a2;
  margin-bottom: 1rem;
}

.libraries_list_box {
  display: flex;
  justify-content: center;
}

.libraries_list {
  display: inline-block;

  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.4);
  border-radius: 0.4rem;
  list-style: none;
  margin: 0;
  padding: 3rem 5rem;
  text-align: center;
}

.libraries_list li {
  font-size: 1.8rem;
  line-height: 1.8;
}

.libraries_list li a {
  transition: all 0.2s;
}

.libraries_list li a:link,
.libraries_list li a:visited {
  text-decoration: none;
  color: black;
}

.libraries_list li a:hover {
  color: rgb(25, 125, 155);
}

/****************
* Media Queries *
*****************/

/* screen > 2000px */
@media (min-width: 125em) {
  .libraries_title {
    font-size: 9.6rem;
  }
  .libraries_list li {
    font-size: 2.8rem;
  }
}

/* screen > 3000px */
@media (min-width: 187.5em) {
  .libraries_list_section {
    padding: 15rem 15rem 5rem;
  }
  .libraries_title {
    font-size: 11.6rem;
    margin-bottom: 2rem;
  }
  .libraries_list li {
    font-size: 3.8rem;
  }
}

/* screen < 1440px */
@media (max-width: 90em) {
  .libraries_title {
    font-size: 6.2rem;
  }
  .libraries_list li {
    font-size: 1.6rem;
  }
}

/* screen < 864px */
@media (max-width: 54em) {
  .libraries_list_section {
    padding: 10rem;
  }
  .libraries_title {
    font-size: 4.2rem;
  }
  .libraries_list li {
    font-size: 1.2rem;
  }
}

/* screen < 640px */
@media (max-width: 40em) {
  .libraries_list_section {
    padding: 8rem 5rem 6rem;
  }
  .libraries_title {
    font-size: 3.2rem;
  }
  .libraries_list li {
    font-size: 1rem;
  }
}

/* screen < 480px */
@media (max-width: 30em) {
  .libraries_list_section {
    padding: 7rem 2rem 5rem;
  }
  .libraries_list {
    padding: 1rem 2rem;
  }
  .libraries_title {
    font-size: 2.6rem;
  }
}
