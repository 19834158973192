.contact_sect_title {
  font-family: 'Mont-serrat', sans-serif;
  font-weight: 600;
  text-align: center;
  font-size: 4.8rem;
  text-transform: uppercase;
  color: #fff;
  text-shadow: 0 0 5px #000;
}

.contact_cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 3rem;
  margin-bottom: 10rem;
}

/****************
* Media Queries *
*****************/

/* screen > 2000px */
@media (min-width: 125em) {
  .contact_sect_title {
    font-size: 6.8rem;
    margin-bottom: 1rem;
  }
}

/* screen > 3000px */
@media (min-width: 187.5em) {
  .contact_sect_title {
    font-size: 8.8rem;
    margin-bottom: 2rem;
  }
}

/* screen < 1600px */
@media (max-width: 100em) {
  .contact_sect_title {
    font-size: 3.8rem;
  }
}

/* screen < 1280px */
@media (max-width: 80em) {
  .contact_sect_title {
    font-size: 3rem;
  }
}

/* screen < 580px */
@media (max-width: 36.25em) {
  .contact_sect_title {
    font-size: 2.2rem;
  }
}
