.section_display {
  padding: 13rem 15rem 10rem;
  min-height: 90vh;
  font-family: 'Montserrat', sans-serif;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='1440' height='560' preserveAspectRatio='none' viewBox='0 0 1440 560'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1162%26quot%3b)' fill='none'%3e%3cpath d='M -765.3615580144706%2c247 C -669.36%2c281.6 -477.36%2c438 -285.36155801447063%2c420 C -93.36%2c402 2.64%2c177.6 194.63844198552937%2c157 C 386.64%2c136.4 482.64%2c311 674.6384419855294%2c317 C 866.64%2c323 1001.57%2c167.8 1154.6384419855294%2c187 C 1307.71%2c206.2 1382.93%2c367.8 1440%2c413' stroke='rgba(28%2c 29%2c 119%2c 1)' stroke-width='2'%3e%3c/path%3e%3cpath d='M -218.44281900274564%2c321 C -122.44%2c300.6 69.56%2c204.4 261.55718099725436%2c219 C 453.56%2c233.6 549.56%2c388.6 741.5571809972544%2c394 C 933.56%2c399.4 1029.56%2c252.8 1221.5571809972544%2c246 C 1413.56%2c239.2 1657.87%2c370.2 1701.5571809972544%2c360 C 1745.25%2c349.8 1492.31%2c228 1440%2c195' stroke='rgba(0%2c 253%2c 255%2c 1)' stroke-width='2'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1162'%3e%3crect width='1440' height='560' fill='white'%3e%3c/rect%3e%3c/mask%3e%3c/defs%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.dc_header {
  font-size: 7.6rem;
  font-weight: 500;
  text-align: center;
  margin-bottom: 4rem;
  color: #1282a2;
}

.dc_box {
  width: 100%;
  display: grid;
  gap: 5rem;
  grid-template-columns: 1fr 1fr;
  margin: auto;
}

.dc_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.4);
  padding: 1rem;
  transform: scale(0.9);
  transition: all 0.4s;
}

.dc_item:hover {
  transform: scale(1);
}

.dc_item img {
  width: 100%;
}

.dc_title {
  display: inline-block;
  margin-top: 2rem;
  border: 1px solid black;
  padding: 1rem 2rem;
  font-size: 2rem;
  font-weight: 500;
  text-transform: uppercase;
}

.dc_title:link,
.dc_title:visited,
.dc_title:active {
  text-decoration: none;
  color: black;
}

.dc_title:hover {
  color: #1282a2;
}

/****************
* Media Queries *
*****************/
/* screen > 2000px */
@media (min-width: 125em) {
  .section_display {
    padding: 15rem 15rem;
  }
  .dc_header {
    font-size: 9.2rem;
  }
  .dc_box {
    gap: 10rem;
  }
  .dc_title {
    margin: 3rem 0;
    border: 2px solid black;
    padding: 1rem 3rem;
    font-size: 3.2rem;
  }
}

/* screen > 3000px */
@media (min-width: 187.5em) {
  .section_display {
    padding: 20rem 15rem;
  }
  .dc_header {
    font-size: 11.8rem;
  }
  .dc_title {
    margin: 5rem 0;
    border: 2px solid black;
    padding: 2rem 5rem;
    font-size: 5.2rem;
  }
}

/* screen > 1600px */
@media (max-width: 100em) {
  .dc_header {
    font-size: 6.2rem;
    margin-bottom: 3rem;
  }
  .dc_title {
    font-size: 1.6rem;
  }
}

/* screen > 1200px */
@media (max-width: 75em) {
  .section_display {
    padding: 13rem 10rem 10rem;
  }
  .dc_header {
    font-size: 5.2rem;
    margin-bottom: 2rem;
  }
  .dc_box {
    gap: 3rem;
  }
  .dc_title {
    font-size: 1.4rem;
    padding: 0.5rem 1rem;
  }
}

/* screen > 960px */
@media (max-width: 60em) {
  .section_display {
    padding: 12rem 7rem 10rem;
  }
  .dc_header {
    font-size: 4.6rem;
    margin-bottom: 1.5rem;
  }
  .dc_box {
    gap: 2rem;
  }
}

/* screen > 800px */
@media (max-width: 50em) {
  .section_display {
    padding: 9rem 4rem 8rem;
  }
  .dc_header {
    font-size: 3.8rem;
    margin-bottom: 1.5rem;
  }
  .dc_box {
    gap: 1rem;
  }
  .dc_title {
    font-size: 1.2rem;
  }
}

/* screen > 640px */
@media (max-width: 40em) {
  .section_display {
    padding: 9rem 2rem 5rem;
  }
  .dc_header {
    font-size: 3.2rem;
    margin-bottom: 1.5rem;
  }
  .dc_title {
    font-size: 1rem;
  }
}

/* screen > 560px */
@media (max-width: 35em) {
  .section_display {
    padding: 7.5rem 2rem 5rem;
  }
  .dc_header {
    font-size: 2.6rem;
    margin-bottom: 1.5rem;
  }
  .dc_title {
    font-size: 1rem;
  }
}
