.about_section {
  padding: 18rem 15rem;
  color: #fff;
  background: linear-gradient(
    180deg,
    rgba(42, 84, 112, 1) 0%,
    rgba(13, 43, 91, 1) 41%,
    rgb(11, 94, 110) 100%
  );
  font-family: 'Montserrat', sans-serif;
}

/* projects */
.projects_box,
.projects_body,
.years_box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.projects_body {
  margin-bottom: 15rem;
}

.projects_title {
  color: #fff;
  text-shadow: 0px 2px 2px #000;
  font-weight: 600;
  font-size: 4.2rem;
  margin-bottom: 2rem;
  text-transform: uppercase;
}

.projects_gallery {
  display: flex;
  flex-wrap: wrap;
  column-gap: 2rem;
  row-gap: 8rem;
}

.projects_gallery_item {
  flex: 1 1 24%;
  text-align: center;
}

.gallery_image {
  height: 30rem;
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.8);
  overflow: hidden;
}

.gallery_image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  transition: all 0.4s;
}

.gallery_image img:hover {
  transform: scale(1.1);
}

.item_title {
  color: #99efff;
  margin-top: 2rem;
  font-size: 2.2rem;
  font-weight: 600;
}

.item_location {
  font-size: 1.8rem;
  font-weight: 500;
  text-transform: uppercase;
}

.item_location svg {
  color: #ff4c29;
}

.mission_box {
  display: grid;
  grid-template-columns: 1fr 1.3fr;
  column-gap: 5rem;
}

.mission_body {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  font-weight: 500;
}

.mission_body p {
  font-size: 2.6rem;
  line-height: 1.2;
  margin-bottom: 2rem;
}

.environment_tag {
  color: #99e65e;
}

.years_box {
  background-color: #ff4c29;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 0 2rem;
}

.years_number {
  font-size: 4.8rem;
  font-weight: 700;
}

.years_text {
  text-transform: uppercase;
  font-size: 1.6rem;
  font-weight: 600;
  text-align: center;
}

/****************
* Media Queries *
*****************/

/* screen > 2000px */
@media (min-width: 125em) {
  .projects_title {
    font-size: 6.8rem;
  }
  .gallery_image {
    height: 40rem;
  }
  .item_title {
    font-size: 2.6rem;
  }
  .item_location {
    font-size: 2.2rem;
  }
  .years_number {
    font-size: 5.8rem;
  }
  .years_text {
    font-size: 2.6rem;
  }
  .mission_body p {
    font-size: 3.2rem;
  }
}

/* screen > 3000px */
@media (min-width: 187.5em) {
  .projects_title {
    font-size: 8.8rem;
  }
  .gallery_image {
    height: 50rem;
  }
  .item_title {
    font-size: 4.6rem;
  }
  .item_location {
    font-size: 3.4rem;
  }
  .years_number {
    font-size: 9.8rem;
  }
  .years_text {
    font-size: 3.6rem;
  }
  .mission_body p {
    font-size: 5.2rem;
  }
}

/* screen < 1600px */
@media (max-width: 100em) {
  .projects_title {
    font-size: 3.8rem;
  }
  .gallery_image {
    height: 25rem;
  }
  .item_title {
    font-size: 2rem;
  }
  .item_location {
    font-size: 1.6rem;
  }
  .years_number {
    font-size: 4.2rem;
  }
  .years_text {
    font-size: 1.4rem;
  }
  .mission_body p {
    font-size: 2.2rem;
  }
}

/* screen < 1400px */
@media (max-width: 87.5em) {
  .about_section {
    padding: 15rem 10rem;
  }
}

/* screen < 1200px */
@media (max-width: 75em) {
  .projects_gallery_item {
    flex: 1 1 40%;
  }
}

/* screen < 1000px */
@media (max-width: 62.5em) {
  .about_section {
    padding: 12rem 8rem;
  }
  .years_number {
    font-size: 3.2rem;
  }
  .years_text {
    font-size: 1.2rem;
  }
  .mission_box {
    column-gap: 3rem;
  }
  .mission_body p {
    font-size: 1.6rem;
  }
}

/* screen < 800px */
@media (max-width: 50em) {
  .about_section {
    padding: 10rem 6rem;
  }
  .projects_title {
    font-size: 2.8rem;
  }
  .gallery_image {
    height: 20rem;
  }
  .item_title {
    font-size: 1.6rem;
  }
  .item_location {
    font-size: 1.2rem;
  }
  .years_number {
    font-size: 2.6rem;
  }
  .mission_box {
    column-gap: 2rem;
  }
  .mission_body p {
    font-size: 1.4rem;
  }
}

/* screen < 640px */
@media (max-width: 40em) {
  .about_section {
    padding: 8rem 4rem;
  }
  .projects_gallery_item {
    flex: 1 1 90%;
  }
  .gallery_image {
    height: 24rem;
  }
  .mission_box {
    grid-template-columns: 1fr;
  }
  .years_box {
    margin-bottom: 2rem;
    padding: 2rem 2.5rem;
  }
}

/* screen < 400px */
@media (max-width: 25em) {
  .gallery_image {
    height: 18rem;
  }
  .projects_gallery {
    row-gap: 5rem;
  }
}
