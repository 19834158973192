.product_information {
  margin-top: 15rem;
  padding: 0 16rem;
  border-top: 2px solid #eee;
}

.product_information_title {
  margin-top: 3rem;
  margin-bottom: 8rem;
  font-size: 3.2rem;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  color: #1282a2;
}

/****************
* Media Queries *
*****************/

/* screen > 2000px */
@media (min-width: 125em) {
  .product_information_title {
    font-size: 5.6rem;
  }
}

/* screen > 3000px */
@media (min-width: 187.5em) {
  .product_information_title {
    font-size: 7.6rem;
  }
}

/* screen < 1600px */
@media (max-width: 100em) {
  .product_information {
    margin-top: 13rem;
    padding: 0 10rem;
  }
  .product_information_title {
    margin-bottom: 4rem;
    font-size: 2.8rem;
  }
}

/* screen < 960px */
@media (max-width: 60em) {
  .product_information {
    margin-top: 10rem;
    padding: 0;
  }
  .product_information_title {
    margin-bottom: 2rem;
    font-size: 2.2rem;
  }
}

/* screen < 480px */
@media (max-width: 30em) {
  .product_information_title {
    margin-bottom: 2rem;
    font-size: 1.8rem;
  }
}
