.iwt_info {
  padding: 0 5rem;
}

.safety_box,
.dimensions_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10rem;
}

.iwt_header {
  font-weight: 500;
  font-size: 2.6rem;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 1rem;
}

.safety_text_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5rem;
}

.safety_description {
  font-size: 1.8rem;
  text-transform: uppercase;
  text-align: center;
  padding: 0 5rem;
}

.safety_image_box,
.dimensions_image_box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.safety_image_box img,
.dimensions_image_box img,
.iwt_info > div:last-child img {
  width: 75%;
}

.safety_image_box img:first-child {
  margin-bottom: 10rem;
}

.dimensions_image_box img:first-child {
  margin-bottom: 5rem;
}

/****************
* Media Queries *
*****************/
/* screen > 2000px */
@media (min-width: 125em) {
  .iwt_header {
    font-size: 3.2rem;
  }
  .safety_description {
    font-size: 2.4rem;
  }
  .iwt_info {
    padding: 0 35rem;
  }
}

/* screen > 3000px */
@media (min-width: 187.5em) {
  .iwt_header {
    font-size: 5.6rem;
  }
  .safety_description {
    font-size: 3.8rem;
  }
  .iwt_info {
    padding: 0 30rem;
  }

  .iwt_info > div:last-child img {
    width: 60%;
  }
}

/* screen < 1600px */
@media (max-width: 100em) {
  .iwt_header {
    font-size: 1.8rem;
  }
  .safety_description {
    font-size: 1.4rem;
  }
}

/* screen < 1200px */
@media (max-width: 75em) {
  .safety_image_box img,
  .dimensions_image_box img,
  .iwt_info > div:last-child img {
    width: 90%;
  }
  .safety_text_box {
    margin-bottom: 2rem;
  }
}

/* screen < 960px */
@media (max-width: 60em) {
  .iwt_info {
    padding: 0;
  }
  .iwt_header {
    font-size: 1.6rem;
    margin-bottom: 1rem;
  }
  .safety_description {
    font-size: 1.2rem;
  }
  .safety_box,
  .dimensions_box,
  .safety_image_box img:first-child {
    margin-bottom: 5rem;
  }
}

/* screen < 800px */
@media (max-width: 50em) {
  .safety_description {
    padding: 0 2rem;
  }
}

/* screen < 600px */
@media (max-width: 37.5em) {
  .safety_description {
    padding: 0 1rem;
  }
  .safety_description {
    font-size: 1rem;
  }
  .safety_image_box img:first-child,
  .dimensions_image_box img:first-child {
    margin-bottom: 3rem;
  }
}

/* screen < 480px */
@media (max-width: 30em) {
  .iwt_header {
    font-size: 1.4rem;
    margin-bottom: 0.5rem;
  }
}
