.contact_card {
  font-family: 'Mont-serrat', sans-serif;
  padding: 2rem 0;
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.4rem;
  color: #fff;
}

.cards_name {
  font-size: 3.2rem;
  font-weight: 700;
  color: #eee;
  text-shadow: 0px 2px 3px #000;
}

.cards_title {
  font-size: 2rem;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  color: #00f7ff;
  text-shadow: 0px 2px 2px #000;
  line-height: 1.2;
}

.cards_email_box,
.cards_extension {
  display: flex;
  align-items: center;
}

.cards_email_box svg,
.cards_extension svg {
  height: 2rem;
  width: 2rem;
  margin-right: 0.6rem;
}

.cards_email {
  transition: all 0.2s;
}

.cards_email,
.cards_extension {
  font-size: 1.8rem;
  font-weight: 500;
}

.cards_email:link,
.cards_email:visited {
  text-decoration: none;
  color: #ff731d;
}

.cards_email:hover,
.cards_email:active {
  color: #ff6200;
}

.cards_extension {
  font-size: 1.6rem;
}

/* screen > 2000px */
@media (min-width: 125em) {
  .cards_name {
    font-size: 3.8rem;
  }
  .cards_title {
    font-size: 2.6rem;
  }
  .cards_email_box svg,
  .cards_extension svg {
    height: 3rem;
    width: 3rem;
  }
  .cards_email,
  .cards_extension {
    font-size: 2.4rem;
  }
}

/* screen > 3000px */
@media (min-width: 187.5em) {
  .cards_name {
    font-size: 5.2rem;
  }
  .cards_title {
    font-size: 4rem;
  }
  .cards_email_box svg,
  .cards_extension svg {
    height: 5rem;
    width: 5rem;
  }
  .cards_email,
  .cards_extension {
    font-size: 3.8rem;
  }
}

/* screen < 1600px */
@media (max-width: 100em) {
  .cards_name {
    font-size: 2.4rem;
  }
  .cards_title {
    font-size: 1.6rem;
  }
  .cards_email_box svg,
  .cards_extension svg {
    height: 1.5rem;
    width: 1.5rem;
  }
  .cards_email,
  .cards_extension {
    font-size: 1.4rem;
  }
}

/* screen < 1280px */
@media (max-width: 80em) {
  .cards_name {
    font-size: 2rem;
  }
  .cards_title {
    font-size: 1.4rem;
  }
  .cards_email,
  .cards_extension {
    font-size: 1.2rem;
  }
}

/* screen < 580px */
@media (max-width: 36.25em) {
  .cards_name {
    font-size: 1.6rem;
  }
  .cards_title {
    font-size: 1.2rem;
  }
  .cards_email_box svg,
  .cards_extension svg {
    height: 1rem;
    width: 1rem;
  }
  .cards_email,
  .cards_extension {
    font-size: 1rem;
  }
}
