/* Panels Section */
.panel_box {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 6rem;
  margin-bottom: 10rem;
  text-transform: uppercase;
}

.sliding_marker_header,
.panel_configuration_header {
  text-align: center;
  font-weight: 600;
  font-size: 2.2rem;
}

.sliding_marker_subheader {
  text-align: center;
  font-weight: 500;
  font-size: 1.8rem;
}

.panel_configuration_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4rem;
}

.panel_box img {
  width: 100%;
  height: auto;
}

/****************
* Media Queries *
*****************/
/* screen > 2000px */
@media (min-width: 125em) {
  .sliding_marker_header,
  .panel_configuration_header {
    font-size: 2.6rem;
  }
  .sliding_marker_subheader {
    font-size: 2.2rem;
  }
}

/* screen > 3000px */
@media (min-width: 187.5em) {
  .sliding_marker_header,
  .panel_configuration_header {
    font-size: 4.6rem;
  }
  .sliding_marker_subheader {
    font-size: 3.8rem;
  }
}

/* screen < 1600px */
@media (max-width: 100em) {
  .additional_price {
    font-size: 1.2rem;
  }
}

/* screen < 1200px */
@media (max-width: 75em) {
  .sliding_marker_header,
  .panel_configuration_header {
    font-size: 1.8rem;
  }
  .sliding_marker_subheader {
    font-size: 1.4rem;
  }
  .panel_configuration_box {
    padding: 0;
  }
}

/* screen < 960px */
@media (max-width: 60em) {
  .panel_box {
    gap: 4rem;
  }
  .additional_price {
    font-size: 1rem;
    grid-column: 1/4;
  }
  .sliding_marker_header,
  .panel_configuration_header {
    font-size: 1.6rem;
  }
  .sliding_marker_subheader {
    font-size: 1.2rem;
  }
}

/* screen < 640px */
@media (max-width: 60em) {
  .panel_box {
    gap: 2rem;
  }
  .sliding_marker_header,
  .panel_configuration_header {
    font-size: 1.2rem;
  }
  .sliding_marker_subheader {
    font-size: 1rem;
  }
}
