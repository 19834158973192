.dc_detail_box {
  display: grid;
  grid-template-columns: 4.5fr 5.5fr;
  gap: 8rem;
}

.dc_detail_box > div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dc_detail_title {
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 2.6rem;
  margin-bottom: 2rem;
}

.dc_detail_box > div img {
  width: 100%;
}

/****************
* Media Queries *
*****************/
/* screen > 2000px */
@media (min-width: 125em) {
  .dc_detail_title {
    font-size: 3.8rem;
  }
}

/* screen > 3000px */
@media (min-width: 187.5em) {
  .dc_detail_title {
    font-size: 6.2rem;
  }
}

/* screen < 1600px */
@media (max-width: 100em) {
  .dc_detail_title {
    font-size: 2rem;
  }
}

/* screen < 960px */
@media (max-width: 60em) {
  .dc_detail_title {
    font-size: 1.6rem;
  }
  .dc_detail_box {
    grid-template-columns: 5.5fr 4.5fr;
    gap: 5rem;
  }
}

/* screen < 840px */
@media (max-width: 52.5em) {
  .dc_detail_title {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
  .dc_detail_box {
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }
}

/* screen < 520px */
@media (max-width: 32.5em) {
  .dc_detail_title {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
  .dc_detail_box {
    grid-template-columns: 1fr;
    gap: 3rem;
  }
}
