.table_comp_header {
  font-weight: 500;
  font-size: 2.6rem;
  text-transform: uppercase;
  margin-bottom: 2rem;
}

.tc_box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tc_box img {
  width: 100%;
}

/****************
* Media Queries *
*****************/
/* screen > 2000px */
@media (min-width: 125em) {
  .table_comp_header {
    font-size: 3.2rem;
  }
}

/* screen > 3000px */
@media (min-width: 187.5em) {
  .table_comp_header {
    font-size: 5.6rem;
  }
}

/* screen < 1600px */
@media (max-width: 100em) {
  .table_comp_header {
    font-size: 1.8rem;
  }
}

/* screen < 960px */
@media (max-width: 60em) {
  .table_comp_header {
    font-size: 1.6rem;
    margin-bottom: 1rem;
  }
}

/* screen < 480px */
@media (max-width: 30em) {
  .table_comp_header {
    font-size: 1.4rem;
    margin-bottom: 0.5rem;
  }
}
