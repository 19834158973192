.product_body_box {
  position: relative;

  display: flex;
  flex-direction: column;
  padding: 2rem 3rem;
  border-radius: 0.6rem;
  box-shadow: 0 0 0.6rem rgba(0, 0, 0, 0.4);
}

.product_body_title {
  color: #1282a2;
  font-size: 3.6rem;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.1;
}

.product_body_description {
  font-size: 2rem;
  margin-bottom: 10rem;
}

.product_body_description li {
  margin-top: 0.8rem;
  line-height: 1.2;
}

/****************
* Media Queries *
*****************/

/* screen > 2000px */
@media (min-width: 125em) {
  .product_body_box {
    padding: 4rem 6rem;
  }
  .product_body_title {
    font-size: 4.8rem;
    margin-bottom: 2rem;
  }
  .product_body_description {
    font-size: 2.4rem;
    margin-bottom: 12rem;
  }
}

/* screen > 3000px */
@media (min-width: 187.5em) {
  .product_body_box {
    padding: 4rem 8rem;
  }
  .product_body_title {
    font-size: 6rem;
  }
  .product_body_description {
    font-size: 4rem;
    margin-bottom: 15rem;
  }
}

/* screen < 1600px */
@media (max-width: 100em) {
  .product_body_title {
    font-size: 3.2rem;
  }
  .product_body_description {
    font-size: 1.8rem;
  }
}

/* screen < 1440px */
@media (max-width: 90em) {
  .product_body_title {
    font-size: 2.6rem;
  }
  .product_body_description {
    font-size: 1.4rem;
  }
}

/* screen < 1200px */
@media (max-width: 75em) {
  .product_body_title {
    font-size: 2.6rem;
  }
  .product_body_description {
    font-size: 1.2rem;
  }
}

/* screen < 1080px */
@media (max-width: 67.5em) {
  .product_body_description li {
    margin-top: 0.5rem;
    line-height: 1.4;
  }
}

/* screen < 840px */
@media (max-width: 52.5em) {
  .product_body_description {
    margin-bottom: 5rem;
  }
}

/* screen < 600px */
@media (max-width: 37.5em) {
  .product_body_title {
    font-size: 2rem;
  }
}

/* screen < 460px */
@media (max-width: 28.75em) {
  .product_body_box {
    padding: 1.5rem 2rem;
  }
  .product_body_title {
    font-size: 1.8rem;
  }
  .product_body_description {
    font-size: 1rem;
  }
}
