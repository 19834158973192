.hero_section {
  position: relative;
  font-family: 'abel', sans-serif;
}

.hero_section::after {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
  z-index: -1;
  content: url('../../assets/images/homepage/escribano_walk1.jpg')
    url('../../assets/images/homepage/escribano.png')
    url('../../assets/images/homepage/visualdisplay.jpg')
    url('../../assets/images/homepage/tables.jpg')
    url('../../assets/images/homepage/tackwall.jpg');
}

.background {
  z-index: 1;
  max-width: 100vw;
  height: 100vh;
  overflow: hidden;

  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-transition: all 0.1s;
  transition: all 0.4s ease-in-out 0.1s;
}

.product_titles_box {
  position: absolute;
  top: 75%;
  left: 50%;
  width: 100%;
  text-align: center;
  z-index: 2;
  transform: translate(-50%, -50%);
  color: #fff;
}

.product_titles_box a:link,
.product_titles_box a:visited {
  color: #fff;
}

.product_titles_box div div {
  display: inline-block;
  padding: 2rem 0;
}

.product_title_line1 {
  display: flex;
}

a .btn {
  font-size: 7.8rem;
  text-transform: uppercase;
  text-shadow: 3px 3px 3px #000;
}

a .btn:hover {
  cursor: pointer;
}

/****************
* Media Queries *
*****************/

/* screen > 2000px */
@media (min-width: 125em) {
  a .btn {
    font-size: 8.6rem;
  }
}

/* screen > 3000px */
@media (min-width: 187.5em) {
  a .btn {
    font-size: 13.2rem;
  }
}

/* screen < 1320px */
@media (max-width: 82.5em) {
  .product_titles_box {
    top: 65%;
  }

  a .btn {
    font-size: 6.2rem;
  }
}

/* screen < 1024px */
@media (max-width: 64em) {
  a .btn {
    font-size: 5.2rem;
  }
}

/* screen < 840px */
@media (max-width: 52.5em) {
  .product_titles_box {
    top: 60%;
  }

  a .btn {
    font-size: 4.2rem;
  }
}

/* screen < 680px */
@media (max-width: 42.5em) {
  a .btn {
    font-size: 3.2rem;
  }
}

/* screen < 520px */
@media (max-width: 32.5em) {
  .product_titles_box div div {
    padding: 1rem 0;
  }

  a .btn {
    font-size: 2.6rem;
  }
}

/* screen < 480px */
@media (max-width: 30em) {
  .background {
    height: 55vh;
  }
}

/* screen < 420px */
@media (max-width: 26.25em) {
  a .btn {
    font-size: 2.2rem;
  }
}

/* screen < 360px */
@media (max-width: 22.5em) {
  a .btn {
    font-size: 2rem;
  }
}
