.natural_cork_box {
  padding: 5rem 6rem;
}

.natural_cork_title {
  text-transform: uppercase;
  font-size: 2.6rem;
  font-weight: 500;
  text-align: center;
  margin-bottom: 3rem;
}

.natural_cork_grid {
  display: grid;
  grid-template-columns: 4.5fr 5.5fr;
  gap: 8rem;
}

.natural_cork_description {
  display: flex;
  align-items: center;
  font-size: 2rem;
  font-weight: 500;
}

.natural_cork_image_box {
  border-radius: 0.4rem;
  overflow: hidden;
  box-shadow: 0 0 0.4rem rgba(0, 0, 0, 0.6);
}

.natural_cork_image_box img {
  width: 100%;
}

/****************
* Media Queries *
*****************/
/* screen > 2000px */
@media (min-width: 125em) {
  .natural_cork_title {
    font-size: 3.2rem;
  }
  .natural_cork_description {
    font-size: 3rem;
  }

  .natural_cork_image_box {
    border-radius: 0.6rem;
    box-shadow: 0 0 0.8rem rgba(0, 0, 0, 0.8);
  }
}

/* screen > 3000px */
@media (min-width: 187.5em) {
  .natural_cork_box {
    padding: 10rem 15rem;
  }
  .natural_cork_title {
    font-size: 5.6rem;
  }
  .natural_cork_description {
    font-size: 4.4rem;
  }
}

/* screen < 1600px */
@media (max-width: 100em) {
  .natural_cork_grid {
    gap: 5rem;
  }
  .natural_cork_title {
    font-size: 1.8rem;
  }
  .natural_cork_description {
    font-size: 1.6rem;
  }
}

/* screen < 1200px */
@media (max-width: 75em) {
  .natural_cork_grid {
    gap: 3rem;
  }
  .natural_cork_box {
    padding: 2rem 3rem;
  }
  .natural_cork_description {
    font-size: 1.4rem;
  }
}

/* screen < 960px */
@media (max-width: 60em) {
  .natural_cork_title {
    font-size: 1.6rem;
    margin-bottom: 1rem;
  }
  .natural_cork_box {
    padding: 0;
  }
  .natural_cork_description {
    font-size: 1.2rem;
  }
}

/* screen < 680px */
@media (max-width: 42.5em) {
  .natural_cork_title {
    margin-bottom: 1.5rem;
  }
  .natural_cork_grid {
    grid-template-columns: 1fr;
    gap: 0.5rem;
  }
  .natural_cork_description {
    padding: 1rem;
    line-height: 1.6;
    grid-row: 2/3;
  }
}

/* screen < 480px */
@media (max-width: 30em) {
  .natural_cork_title {
    font-size: 1.4rem;
  }
  .natural_cork_description {
    font-size: 1rem;
  }
}
