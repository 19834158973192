.product_section {
  min-height: 100vh;
  padding: 14rem 8rem;
  font-family: 'Montserrat', sans-serif;
}

/****************
* Media Queries *
*****************/

/* screen > 2000px */
@media (min-width: 125em) {
  .product_section {
    padding: 18rem 10rem;
  }
}

/* screen > 3000px */
@media (min-width: 187.5em) {
  .product_section {
    padding: 22rem 12rem;
  }
}

/* screen < 1440px */
@media (max-width: 90em) {
  .product_section {
    padding: 14rem 6rem;
  }
}

/* screen < 1080px */
@media (max-width: 67.5em) {
  .product_section {
    padding: 12rem 4rem;
  }
}

/* screen < 840px */
@media (max-width: 52.5em) {
  .product_section {
    min-height: 90vh;
    padding: 10rem 8rem;
  }
}

/* screen < 640px */
@media (max-width: 40em) {
  .product_section {
    padding: 8rem 5rem;
  }
}

/* screen < 460px */
@media (max-width: 28.75em) {
  .product_section {
    padding: 6rem 3rem;
  }
}
