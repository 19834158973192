.header-section,
.offcanvas-body {
  font-family: 'abel', sans-serif;
}

.header-section {
  width: 100%;
  color: #fff;
  position: absolute;
  top: 0;
  z-index: 3;

  display: flex;
  padding: 2rem 3rem;
  align-items: center;
  justify-content: space-between;
}

/* Logo */
.header-logo {
  display: flex;
  align-items: center;
  gap: 1rem;
  transition: all 0.4s;
}

.logo-box {
  height: 9rem;
  width: 9rem;
}

.logo {
  width: 100%;
  border-radius: 50%;
  box-shadow: -3px 5px 10px rgb(0, 0, 0, 0.4);
  transition: all 0.6s;
}

.logo:hover {
  transform: scale(1.1);
  box-shadow: -5px 7px 15px rgb(0, 0, 0, 0.4);
}

/* Menu */
.menu {
  color: #000;
  font-size: 2.4rem;
  padding: 0.5rem;
  text-transform: uppercase;
  cursor: pointer;
  border: 1px solid #000;

  transition: all 0.5s;
}

.menu:hover {
  background-color: #034078;
  color: #fff;
}

.menu_active,
.sub-nav-list {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

.header-section.white .menu {
  color: #fff;
  border: 1px solid #fff;
}

.header-section.white .menu:hover,
.header-section.white .menu_active {
  background-color: #fff;
  color: #000;
}

/* off canvas */
.offcanvas-backdrop.show {
  opacity: 0.7;
}

.offcanvas {
  background-color: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);
}

.offcanvas-header {
  padding: 2rem 2rem 0 0;
  justify-content: end;
}

.offcanvas-header .btn-close {
  padding: 2rem;
  justify-content: end;
  font-size: 2.5rem;
}

.offcanvas-body {
  overflow-x: hidden;
}

.btn-close:focus {
  box-shadow: none;
}

/* Navigation */
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-list,
.nav-list a {
  color: #000;
}

ul.nav-list {
  width: 100%;
  text-align: center;
  font-size: 3.6rem;
}

.nav-list li a,
.sub-nav-list-header div {
  width: 100%;
  transition: all 0.3s;
}

.nav-list li a:link {
  display: inline-block;
}

.nav-list li a:link,
.nav-list li a:visited {
  text-decoration: none;
}

.nav-list li a:hover,
.sub-nav-list-header div:hover {
  background-color: transparent;
  transform: scale(1.2);
  color: #033a6c;
}

.sub-nav-list-header > div {
  cursor: pointer;
}

.sub-nav-list-header div svg {
  font-size: 2.5rem;
  margin-left: 0.5rem;
}

.sub-nav-list-header li {
  font-size: 2.2rem;
}

/* Sub Navigation animations */
.sub-nav-list {
  line-height: 1.7;
  transition: opacity 0.2s;
}

.show {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.translate {
  transition: transform 0.4s;
}

.translate-arch-up {
  transform: translateY(-19rem);
  transition: transform 0.5s;
}

.translate-up {
  transform: translateY(-15rem);
}

/****************
* Media Queries *
*****************/

/* screen > 2000px */
@media (min-width: 125em) {
  .logo-box {
    height: 12rem;
    width: 12rem;
  }
  .header-section.white .menu {
    border: 2px solid #fff;
  }
  .offcanvas-header .btn-close {
    font-size: 3.5rem;
  }
  .menu {
    font-size: 3.4rem;
    padding: 1rem;
  }
  .offcanvas.offcanvas-end {
    width: 60rem;
  }

  ul.nav-list {
    font-size: 5.2rem;
  }
  .sub-nav-list-header li {
    font-size: 3.2rem;
  }
  .sub-nav-list-header div svg {
    font-size: 4rem;
  }

  .translate-arch-up {
    transform: translateY(-28rem);
    transition: transform 0.5s;
  }

  .translate-up {
    transform: translateY(-22.5rem);
  }
}

/* screen > 3000px */
@media (min-width: 187.5em) {
  .logo-box {
    height: 17rem;
    width: 17rem;
  }
  .offcanvas-header .btn-close {
    font-size: 4.5rem;
  }
  .menu {
    padding: 1.5rem;
    font-size: 4.4rem;
  }
  .offcanvas.offcanvas-end {
    width: 90rem;
  }
  ul.nav-list {
    font-size: 8.2rem;
  }
  .sub-nav-list-header li {
    font-size: 5.2rem;
  }
  .sub-nav-list-header div svg {
    font-size: 6rem;
  }
  .translate-arch-up {
    transform: translateY(-45rem);
  }
  .translate-up {
    transform: translateY(-36rem);
  }
}

/* screen < 1200px */
@media (max-width: 75em) {
  .logo-box {
    height: 8rem;
    width: 8rem;
  }
  .menu {
    font-size: 2rem;
  }
  .offcanvas.offcanvas-end {
    width: 35rem;
  }
  .offcanvas-header {
    padding: 0;
  }
  .offcanvas-header .btn-close {
    font-size: 2rem;
  }
  ul.nav-list {
    font-size: 3rem;
  }
  .sub-nav-list-header li {
    font-size: 1.8rem;
  }
  .sub-nav-list-header div svg {
    font-size: 2rem;
  }
  .translate-arch-up {
    transform: translateY(-15rem);
  }
  .translate-up {
    transform: translateY(-12rem);
  }
}

/* screen < 1024px */
@media (max-width: 64em) {
  .offcanvas-header {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  .offcanvas-body {
    padding-top: 6rem;
  }
}

/* screen < 928px */
@media (max-width: 58em) {
  .offcanvas.offcanvas-end {
    width: 30rem;
  }
  .offcanvas-header .btn-close {
    font-size: 1.5rem;
  }
  ul.nav-list {
    font-size: 2.4rem;
  }
  .sub-nav-list-header li {
    font-size: 1.6rem;
  }
  .translate-arch-up {
    transform: translateY(-13.5rem);
  }
  .translate-up {
    transform: translateY(-10.5rem);
  }
}

/* screen < 800px */
@media (max-width: 50em) {
  .header-section {
    padding: 2rem;
  }
  .logo-box {
    height: 5.5rem;
    width: 5.5rem;
  }
  .menu {
    font-size: 1.4rem;
  }
}

/* screen < 560px */
@media (max-width: 35em) {
  .header-section {
    padding: 1.5rem 1rem;
  }
  .logo-box {
    height: 4.5rem;
    width: 4.5rem;
  }
  .menu {
    font-size: 1.2rem;
  }
}
