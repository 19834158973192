.markerboard_sizes_box {
  margin: 2rem 0 10rem;
}

.markerboard_sizes_title {
  margin-bottom: 2rem;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 2.6rem;
  text-align: center;
}

.markerboard_sizes_list {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-template-rows: repeat(4, 1fr);
  gap: 2rem;
  padding: 0;
  list-style: none;
}

.markerboard_sizes_list li {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
  padding: 1rem 2rem;
  font-size: 1.8rem;
  text-align: center;
}

.markerboard_sizes_list li:last-child {
  font-size: 2rem;
  font-weight: 500;
  text-transform: uppercase;
  color: #034078;
}

/****************
* Media Queries *
*****************/
/* screen > 2000px */
@media (min-width: 125em) {
  .markerboard_sizes_title {
    font-size: 3.2rem;
  }
  .markerboard_sizes_list {
    gap: 3rem;
  }

  .markerboard_sizes_list li,
  .markerboard_sizes_list li:last-child {
    padding: 2rem 2rem;
    font-size: 2.4rem;
  }
}

/* screen > 3000px */
@media (min-width: 187.5em) {
  .markerboard_sizes_title {
    font-size: 5.6rem;
  }
  .markerboard_sizes_list {
    gap: 4rem;
  }
  .markerboard_sizes_list li,
  .markerboard_sizes_list li:last-child {
    padding: 4rem 2rem;
    font-size: 3.6rem;
  }
}

/* screen < 1600px */
@media (max-width: 100em) {
  .markerboard_sizes_title {
    font-size: 1.8rem;
  }
  .markerboard_sizes_list {
    gap: 1.5rem;
  }
  .markerboard_sizes_list li,
  .markerboard_sizes_list li:last-child {
    font-size: 1.6rem;
  }
}

/* screen < 960px */
@media (max-width: 60em) {
  .markerboard_sizes_title {
    font-size: 1.6rem;
  }
  .markerboard_sizes_list {
    gap: 1rem;
  }
  .markerboard_sizes_list li,
  .markerboard_sizes_list li:last-child {
    font-size: 1.4rem;
  }
}

/* screen < 800px */
@media (max-width: 50em) {
  .markerboard_sizes_list {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-rows: repeat(5, 1fr);
  }
}

/* screen < 480px */
@media (max-width: 30em) {
  .markerboard_sizes_box {
    margin: 2rem 0 6rem;
  }
  .markerboard_sizes_title {
    font-size: 1.4rem;
  }
  .markerboard_sizes_list li {
    font-size: 1.2rem;
    padding: 0.5rem 1rem;
  }
  .markerboard_sizes_list li:last-child {
    font-size: 1rem;
  }
}
