.vendors {
  font-family: 'abel', sans-serif;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 12rem;
  padding: 4rem 10rem;
}

.partners_header,
.partners_subheader {
  text-align: center;
}

.partners_header {
  font-size: 4.8rem;
  font-weight: 600;
  line-height: 1;
}

.partners_subheader {
  font-weight: 500;
  margin-bottom: 2rem;
  font-size: 3.2rem;
}

.partners_logo_box,
.vendors_logo_box {
  display: flex;
  justify-content: center;
  gap: 3rem;
  margin-top: 1rem;
}

.lausd,
.garden_grove,
.ucr {
  display: flex;
  align-items: center;
  height: 20rem;
  width: 20rem;
}

.partners_logo_box img,
.vendors_logo_box img {
  width: 100%;
}

.lausd_img,
.garden_grove_img,
.ucr_img {
  border-radius: 50%;
}

/****************
* Media Queries *
*****************/

/* screen > 2000px */
@media (min-width: 125em) {
  .partners_header {
    font-size: 6.2rem;
  }

  .partners_subheader {
    font-size: 4.2rem;
  }

  .partners_logo_box > div {
    height: 30rem;
    width: 30rem;
  }

  .vendors_logo_box > div {
    width: 45rem;
  }
}

/* screen > 3000px */
@media (min-width: 187.5em) {
  .partners_header {
    font-size: 7.6rem;
  }

  .partners_subheader {
    font-size: 5.8rem;
  }

  .partners_logo_box > div {
    height: 35rem;
    width: 35rem;
  }

  .vendors_logo_box > div {
    width: 65rem;
  }
}

/* screen < 1024px */
@media (max-width: 64em) {
  .vendors {
    padding: 4rem 5rem;
    gap: 8rem;
  }

  .partners_header {
    font-size: 3.8rem;
  }

  .partners_subheader {
    font-size: 2.6rem;
  }

  .lausd,
  .garden_grove,
  .ucr {
    height: 15rem;
    width: 15rem;
  }

  .vendors_logo_box {
    gap: 0;
  }
}

/* screen < 780px */
@media (max-width: 48.75em) {
  .partners_header {
    font-size: 3.2rem;
  }

  .partners_subheader {
    font-size: 2.2rem;
  }

  .lausd,
  .garden_grove,
  .ucr {
    height: 11rem;
    width: 11rem;
  }
}

/* screen < 540px */
@media (max-width: 33.75em) {
  .vendors {
    padding: 4rem 3rem;
    gap: 6rem;
  }

  .partners_header {
    font-size: 2.6rem;
  }

  .partners_subheader {
    font-size: 1.6rem;
  }

  .lausd,
  .garden_grove,
  .ucr {
    height: 8rem;
    width: 8rem;
  }
}

/* screen < 400px */
@media (max-width: 25em) {
  .vendors {
    padding: 3rem 2rem;
    gap: 5rem;
  }

  .partners_header {
    font-size: 2.2rem;
  }

  .partners_subheader {
    font-size: 1.2rem;
  }

  .lausd,
  .garden_grove,
  .ucr {
    height: 6rem;
    width: 6rem;
  }
}
