.contact_page {
  background: rgb(42, 84, 112);
  background: linear-gradient(
    180deg,
    rgba(42, 84, 112, 1) 0%,
    rgba(13, 43, 91, 1) 41%,
    rgb(11, 94, 110) 100%
  );
}

.contact_hero {
  position: relative;
  min-height: 105vh;
  background-image: url('../../assets/images/contactus_hero_image.png');
  background-position: right;
  background-size: cover;
  background-repeat: no-repeat;
}

.contact_body {
  position: absolute;
  top: 30%;
  left: 15%;

  display: flex;
  flex-direction: column;
  color: #fff;
}

.contact_header {
  font-weight: 700;
  font-size: 7.2rem;
}

.contact_subheader {
  font-weight: 500;
}

.contact_subheader,
.contact_address_box {
  font-size: 2.8rem;
}

.contact_address_box {
  margin-top: 6rem;
}

.contact_info {
  color: rgb(113, 255, 238);
  font-size: 3.2rem;
  font-weight: 600;
}

.contact_team {
  padding: 10rem 5rem;
}

/****************
* Media Queries *
*****************/

/* screen > 2000px */
@media (min-width: 125em) {
  .contact_header {
    font-size: 9.2rem;
  }
  .contact_subheader,
  .contact_address_box {
    font-size: 4.8rem;
  }
  .contact_info {
    font-size: 5.2rem;
  }
}

/* screen > 3000px */
@media (min-width: 187.5em) {
  .contact_header {
    font-size: 11.2rem;
  }
  .contact_subheader,
  .contact_address_box {
    font-size: 6.8rem;
  }
  .contact_info {
    font-size: 7.2rem;
  }
}

/* screen < 1400px */
@media (max-width: 87.5em) {
  .contact_body {
    left: 10%;
  }
  .contact_header {
    font-size: 5.2rem;
  }
  .contact_subheader,
  .contact_address_box {
    font-size: 2.2rem;
  }
  .contact_info {
    font-size: 2.8rem;
  }
}

/* screen < 1200px */
@media (max-width: 75em) {
  .contact_header {
    font-size: 4.2rem;
  }
  .contact_subheader,
  .contact_address_box {
    font-size: 1.8rem;
  }
  .contact_info {
    font-size: 2.2rem;
  }
}

/* screen < 1040px */
@media (max-width: 65em) {
  .contact_address_box {
    margin-top: 3rem;
  }
}

/* screen < 720px */
@media (max-width: 45em) {
  .contact_hero {
    background-position: right -7rem bottom;
  }
  .contact_header {
    font-size: 3.2rem;
  }
  .contact_subheader,
  .contact_address_box {
    font-size: 1.4rem;
  }
  .contact_info {
    font-size: 1.8rem;
  }
}

/* screen < 540px */
@media (max-width: 33.75em) {
  .contact_hero {
    min-height: 90vh;
    background-position: right -8rem bottom;
  }
  .contact_team {
    padding: 7rem 3rem;
  }
  .contact_body {
    top: 20%;
    padding-right: 2rem;
  }
  .contact_address_box {
    margin-top: 2rem;
  }
  .contact_info {
    font-size: 1.4rem;
  }
}
