.fdw_detail_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10rem;
}

.fdc_detail_title {
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 2.6rem;
  margin-bottom: 2rem;
}

.fdw_detail_images,
.twp_detail_images {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
  align-items: center;
  justify-content: center;
}

.fdw_detail_images > div:first-child,
.twp_detail_images > div:first-child {
  text-align: right;
}

.fdw_detail_images > div:first-child img {
  width: 90%;
}

.fdw_detail_images > div:last-child img {
  width: 80%;
}

.fdw_edging_box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.fdw_edging_box > div,
.twp_detail_images > div,
.fdw_edging_box img {
  width: 100%;
}

.twp_detail_images img {
  max-width: 100%;
}

/****************
* Media Queries *
*****************/

/* screen > 2000px */
@media (min-width: 125em) {
  .fdc_detail_title {
    font-size: 3.6rem;
  }
  .fdw_detail_images > div:first-child img {
    width: 80%;
  }
  .fdw_detail_images > div:last-child img {
    width: 70%;
  }
  .twp_detail_images img {
    width: 65%;
  }
}

/* screen > 3000px */
@media (min-width: 187.5em) {
  .fdc_detail_title {
    font-size: 5.6rem;
  }
}

/* screen > 1600px */
@media (max-width: 100em) {
  .fdc_detail_title {
    font-size: 2.4rem;
  }
}

/* screen > 1280px */
@media (max-width: 80em) {
  .fdc_detail_title {
    font-size: 2.2rem;
  }
  .fdw_detail_images > div:first-child img {
    width: 100%;
  }
  .fdw_detail_images > div:last-child img {
    width: 90%;
  }
}

/* screen > 960px */
@media (max-width: 60em) {
  .fdw_detail_box {
    margin-bottom: 7rem;
  }
  .fdc_detail_title {
    font-size: 1.8rem;
  }
  .twp_detail_images img {
    width: 80%;
  }
}

/* screen > 640px */
@media (max-width: 40em) {
  .fdw_detail_box {
    margin-bottom: 5rem;
  }
  .fdc_detail_title {
    font-size: 1.8rem;
    padding: 0 4rem;
  }
  .fdw_detail_images,
  .twp_detail_images {
    grid-template-columns: 1fr;
  }
  .fdw_detail_images > div,
  .twp_detail_images > div {
    display: flex;
    justify-content: center;
  }
  .fdw_detail_images > div:first-child img,
  .fdw_detail_images > div:last-child img,
  .twp_detail_images img {
    width: 60%;
  }
}

/* screen < 480px */
@media (max-width: 30em) {
  .fdc_detail_title {
    font-size: 1.2rem;
  }
  .fdw_detail_images > div:first-child img {
    width: 85%;
  }
  .fdw_detail_images > div:last-child img {
    width: 75%;
  }
  .twp_detail_images img {
    width: 70%;
  }
}
