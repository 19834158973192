footer {
  font-family: 'abel', sans-serif;
  background-color: #023047;
  min-height: 10rem;
  width: 100%;
  z-index: 3;

  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  padding: 4rem;
  color: #fff;
  font-size: 1.8rem;
  text-transform: uppercase;
}

.footer_title_box {
  display: flex;
  align-items: flex-start;
}

.footer_title_box > div {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.footer_company_logo_box {
  height: 5rem;
  width: 5rem;
}

.footer_title_box img {
  max-width: 100%;
  border-radius: 50%;
}

.footer_company_title {
  font-size: 3.6rem;
}

.footer_links li {
  list-style: none;
}

.footer_links a {
  text-decoration: none;
  color: #fff;
  transition: color 0.3s;
}

.footer_links a:hover {
  color: #00bfe0;
}

/****************
* Media Queries *
*****************/

/* screen > 2000px */
@media (min-width: 125em) {
  footer {
    font-size: 2.4rem;
  }

  .footer_company_logo_box {
    height: 8rem;
    width: 8rem;
  }

  .footer_company_title {
    font-size: 4.2rem;
  }
}

/* screen > 3000px */
@media (min-width: 187.5em) {
  footer {
    font-size: 3.2rem;
  }

  .footer_company_logo_box {
    height: 12rem;
    width: 12rem;
  }

  .footer_company_title {
    font-size: 5.4rem;
  }
}

/* screen < 1040 */
@media (max-width: 65em) {
  footer {
    font-size: 1.6rem;
    padding: 2rem;
    column-gap: 1rem;
    row-gap: 1.5rem;
  }

  .footer_company_logo_box {
    height: 4rem;
    width: 4rem;
  }

  .footer_company_title {
    font-size: 2.6rem;
  }

  .footer_links {
    padding: 0;
  }
}

/* screen < 780px */
@media (max-width: 48.75em) {
  footer {
    font-size: 1.2rem;
  }

  .footer_company_logo_box {
    height: 3.5rem;
    width: 3.5rem;
  }

  .footer_company_title {
    font-size: 2rem;
  }
}

/* screen < 620px */
@media (max-width: 38.75em) {
  footer {
    grid-template-columns: 1fr 0.6fr;
    grid-template-rows: 0.8fr 1.5fr;
  }

  .footer_title_box > div {
    gap: 0.4rem;
  }

  .footer_address {
    grid-column: 1/2;
  }

  .footer_links {
    grid-column: 2/3;
  }
}

/* screen < 480px */
@media (max-width: 30em) {
  footer {
    font-size: 1rem;
    padding: 1rem;
  }

  .footer_company_logo_box {
    height: 3rem;
    width: 3rem;
  }

  .footer_company_title {
    font-size: 1.6rem;
  }
}
