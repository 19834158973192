.additional_price {
  text-transform: uppercase;
  font-size: 1.8rem;
  font-weight: 600;

  grid-column: -5/-3;
}

.tw_details_box {
  text-transform: uppercase;
  margin-bottom: 10rem;
}

.tw_details_box img,
.panel_configuration_box img {
  width: 100%;
  height: auto;
}

.tw_details_title {
  text-align: center;
  font-size: 2.6rem;
  font-weight: 600;
  margin-bottom: 3rem;
}

.tw_details1 {
  display: grid;
  align-items: end;
  grid-template-columns: 3.5fr 1.5fr;
  gap: 5rem;
  margin-bottom: 10rem;
}

.tw_details1 div:last-child img {
  width: 70%;
}

.tw_details_subheader {
  text-align: center;
  font-size: 1.8rem;
  font-weight: 500;
}

.panel_box {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 6rem;
  margin-bottom: 10rem;
}

.sliding_marker_header,
.panel_configuration_header {
  text-align: center;
  font-weight: 600;
  font-size: 2.2rem;
}

.panel_configuration_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4rem;
}

/****************
* Media Queries *
*****************/
/* screen > 2000px */
@media (min-width: 125em) {
  .additional_price {
    font-size: 2.2rem;
  }
  .tw_details_title {
    font-size: 3.2rem;
  }
  .sliding_marker_header,
  .panel_configuration_header {
    font-size: 2.6rem;
  }
  .tw_details_subheader {
    font-size: 2.2rem;
  }
}

/* screen > 3000px */
@media (min-width: 187.5em) {
  .additional_price {
    font-size: 3.2rem;
  }
  .tw_details_title {
    font-size: 5.6rem;
  }
  .sliding_marker_header,
  .panel_configuration_header {
    font-size: 4.6rem;
  }
  .tw_details_subheader {
    font-size: 3.8rem;
  }
}

/* screen < 1600px */
@media (max-width: 100em) {
  .additional_price {
    font-size: 1.2rem;
  }
}

/* screen < 1200px */
@media (max-width: 75em) {
  .tw_details_subheader {
    font-size: 1.4rem;
  }
  .tw_details1 div:last-child img {
    width: 80%;
  }
  .tw_details_title {
    font-size: 2.2rem;
  }
  .sliding_marker_header,
  .panel_configuration_header {
    font-size: 1.8rem;
  }
  .panel_configuration_box {
    padding: 0;
  }
}

/* screen < 960px */
@media (max-width: 60em) {
  .panel_box {
    gap: 4rem;
  }
  .tw_details1,
  .tw_details_box {
    margin-bottom: 5rem;
  }
  .additional_price {
    font-size: 1rem;
    grid-column: 1/4;
  }
  .sliding_marker_header,
  .panel_configuration_header {
    font-size: 1.6rem;
  }
  .tw_details_subheader {
    font-size: 1.2rem;
  }
}

/* screen < 640px */
@media (max-width: 60em) {
  .tw_details1,
  .panel_box {
    gap: 2rem;
  }
  .tw_details1,
  .tw_details_box {
    margin-bottom: 4rem;
  }
  .tw_details_title {
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }
  .sliding_marker_header,
  .panel_configuration_header,
  .tw_details_subheader {
    font-size: 1rem;
  }
}
