.mw_details,
.mw_details > div {
  display: flex;
  justify-content: center;
}

.mw_details > div {
  width: 100%;
}

.mw_details img {
  width: 40%;
}

/****************
* Media Queries *
*****************/

/* screen < 1200px */
@media (max-width: 75em) {
  .mw_details img {
    width: 45%;
  }
}

/* screen < 680px */
@media (max-width: 42.5em) {
  .mw_details img {
    width: 50%;
  }
}

/* screen < 400px */
@media (max-width: 25em) {
  .mw_details img {
    width: 60%;
  }
}
