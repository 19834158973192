.aluminum_trim_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 10rem 0;
}

.customize_options_title {
  text-align: center;
  font-weight: 500;
  font-size: 2.6rem;
  text-transform: uppercase;
}

.customize_images {
  width: 60%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
  padding: 4rem;
}

.customize_images > div h4 {
  margin-top: 1rem;
  text-align: center;
  text-transform: uppercase;
  font-size: 1.6rem;
}

.customize_images img {
  width: 100%;
}

/****************
* Media Queries *
*****************/
/* screen > 2000px */
@media (min-width: 125em) {
  .customize_options_title {
    font-size: 3.2rem;
  }
  .customize_images {
    width: 50%;
  }
  .customize_images > div h4 {
    font-size: 2.2rem;
  }
}

/* screen > 3000px */
@media (min-width: 187.5em) {
  .customize_options_title {
    font-size: 5.6rem;
  }
  .customize_images > div h4 {
    font-size: 3.8rem;
  }
}

/* screen < 1600px */
@media (max-width: 100em) {
  .customize_options_title {
    font-size: 1.8rem;
  }
  .aluminum_trim_box {
    margin: 8rem 0;
  }
  .customize_images {
    padding: 2rem 4rem;
  }
  .customize_images > div h4 {
    font-size: 1.4rem;
  }
}

/* screen < 1200px */
@media (max-width: 75em) {
  .customize_images {
    width: 70%;
  }
}

/* screen < 960px */
@media (max-width: 60em) {
  .customize_images {
    padding: 3rem;
    gap: 2rem;
  }
  .customize_options_title {
    font-size: 1.6rem;
  }
  .customize_images > div h4 {
    font-size: 1.2rem;
  }
}

/* screen < 800px */
@media (max-width: 50em) {
  .customize_images {
    width: 80%;
  }
}

/* screen < 640px */
@media (max-width: 40em) {
  .customize_images {
    width: 90%;
    gap: 1rem;
  }
}

/* screen < 480px */
@media (max-width: 30em) {
  .customize_images {
    padding: 2rem;
  }
  .customize_options_title {
    font-size: 1.4rem;
  }
  .customize_images > div h4 {
    font-size: 1rem;
  }
}

/* screen < 250px */
@media (max-width: 30em) {
  .customize_images {
    width: 100%;
  }
}
