html {
  font-size: 62.5%;
  padding: env(safe-area-inset);
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}

html,
body {
  width: 100%;
}
