.architects_corner {
  min-height: 90vh;
  padding: 13rem 20rem 0;
  font-family: 'Montserrat', sans-serif;
  background: linear-gradient(
    170deg,
    rgba(247, 241, 241, 1) 0%,
    rgba(208, 240, 241, 1) 75%,
    rgba(208, 247, 255, 1) 100%
  );
}

.architects_title {
  text-align: center;
  font-size: 7.6rem;
  font-weight: 500;
  color: #1282a2;
}

.ac_grid {
  display: grid;
  gap: 5rem;
  justify-items: center;
  grid-template-columns: 1fr 1fr;

  margin-top: 2rem;
  padding-bottom: 10rem;
}

.ac_grid > div {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 3rem 5rem;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.4);
  border-radius: 0.4rem;
}

.ac_title {
  font-size: 2.6rem;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  color: #1282a2;
  margin-bottom: 2rem;
}

.library_list {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
}

.library_list li {
  font-size: 1.8rem;
  line-height: 1.8;
}

.library_list li a {
  transition: all 0.2s;
}

.library_list li a:link,
.library_list li a:visited {
  text-decoration: none;
  color: black;
}

.library_list li a:hover {
  color: rgb(25, 125, 155);
}

/****************
* Media Queries *
*****************/

/* screen > 2000px */
@media (min-width: 125em) {
  .architects_title {
    font-size: 9.6rem;
  }
  .ac_grid {
    margin-top: 4rem;
  }
  .ac_title {
    font-size: 4.6rem;
  }
  .library_list li {
    font-size: 2.8rem;
  }
}

/* screen > 3000px */
@media (min-width: 187.5em) {
  .architects_title {
    font-size: 11.6rem;
  }
  .ac_grid,
  .architects_title {
    margin-top: 4rem;
  }
  .ac_grid {
    column-gap: 8rem;
  }
  .ac_grid > div {
    padding: 5rem 10rem;
  }
  .ac_title {
    font-size: 6.6rem;
  }
  .library_list li {
    font-size: 4.2rem;
  }
}

/* screen < 1600px */
@media (max-width: 100em) {
  .architects_corner {
    padding: 13rem 10rem 0;
  }
  .architects_title {
    font-size: 6.2rem;
  }
  .ac_title {
    font-size: 2.4rem;
    margin-bottom: 1rem;
  }
  .library_list li {
    font-size: 1.6rem;
  }
}

/* screen < 1200px */
@media (max-width: 75em) {
  .architects_corner {
    padding: 12rem 8rem 0;
  }
  .ac_grid > div {
    padding: 2rem 3rem;
  }
  .architects_title {
    font-size: 5.2rem;
  }
  .ac_title {
    font-size: 2rem;
  }
  .library_list li {
    font-size: 1.6rem;
  }
}

/* screen < 1056px */
@media (max-width: 66em) {
  .architects_corner {
    padding: 12rem 6rem 0;
  }
  .architects_title {
    font-size: 4.2rem;
  }
  .ac_title {
    font-size: 1.6rem;
  }
  .library_list li {
    font-size: 1.2rem;
  }
}

/* screen < 840px */
@media (max-width: 52.5em) {
  .architects_corner {
    padding: 12rem 5rem 0;
  }
  .ac_grid {
    gap: 3rem;
  }
  .architects_title {
    font-size: 3.2rem;
  }
  .ac_title {
    font-size: 1.4rem;
  }
  .library_list li {
    font-size: 1rem;
  }
}

/* screen < 704px */
@media (max-width: 44em) {
  .ac_grid {
    grid-template-columns: 1fr;
  }
  .ac_grid > div {
    width: 60%;
    margin: 0 auto;
  }
}

/* screen < 600px */
@media (max-width: 37.5em) {
  .ac_grid > div {
    width: 100%;
  }
}

/* screen < 400px */
@media (max-width: 25em) {
  .architects_corner {
    padding: 10rem 2.5rem 0;
  }
  .architects_title {
    font-size: 2.8rem;
  }
  .ac_grid > div {
    padding: 1.5rem 2rem;
  }
}
