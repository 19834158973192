.hero-modal-width {
  max-width: 90rem;
}

.modal-body {
  overflow: hidden;
}

.modal-dialog-centered {
  margin: 0 auto;
}

.magnifier {
  height: 100% !important;
}

.magnifier-image {
  width: 100% !important;
}

.modal-header {
  border-bottom: none;
}

.btn_available_box {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 0 2rem 3rem 0;
  display: flex;
  justify-content: flex-end;
  overflow-y: visible;
}

.btn_available {
  background-color: #cdd9e4;
  text-align: center;
  padding: 1rem 2rem;
  font-size: 2.8rem;
  font-weight: 500;
  box-shadow: 0 0 0.8rem rgba(0, 0, 0.4);
  border-radius: 0.4rem;

  transition: all 0.3s;
}

.btn_available:hover {
  background-color: #426b92 !important;
  color: white;
  cursor: pointer;
  border-color: #426b92;
}

.btn_available:active {
  background-color: #1c5386 !important;
  box-shadow: 0 0 0.4rem rgba(0, 0, 0.8);
}

.dropdown-menu.show {
  /* Do NOT delete; Product Dropdown css bug */
  margin-top: 0.35rem;
  top: 0px !important;
  bottom: auto !important;
  transform: translate3d(0px, 58px, 0) !important;
}

.dropdown-menu.show a {
  font-size: 1.8rem;
}
/****************
* Media Queries *
*****************/

/* screen > 2000px */
@media (min-width: 125em) {
  .hero-modal-width {
    min-width: 120rem;
  }
  .btn-close {
    font-size: 2.5rem;
  }
  .btn_available {
    font-size: 3.6rem;
  }
  .dropdown-menu.show {
    margin-top: 1.8rem;
  }
  .dropdown-menu.show a {
    font-size: 2.4rem;
  }
}

/* screen > 3000px */
@media (min-width: 187.5em) {
  .hero-modal-width {
    min-width: 170rem;
  }
  .btn-close {
    font-size: 4rem;
  }
  .btn_available_box {
    margin: 0 4rem 4rem 0;
  }
  .btn_available {
    font-size: 5.4rem;
  }
  .dropdown-menu.show {
    margin-top: 4.2rem;
  }
  .dropdown-menu.show a {
    font-size: 3.6rem;
  }
}

/* screen < 1600px */
@media (max-width: 100em) {
  .hero-modal-width {
    max-width: 75rem;
  }
  .btn_available {
    font-size: 2.4rem;
  }
  .dropdown-menu.show {
    margin-top: -2px;
  }

  .dropdown-menu.show a {
    font-size: 1.6rem;
  }
}

/* screen < 1440px */
@media (max-width: 90em) {
  .hero-modal-width {
    max-width: 70rem;
  }
  .btn-close {
    font-size: 1.5rem;
  }
  .btn_available {
    font-size: 1.8rem;
  }
  .dropdown-menu.show {
    margin-top: -11px;
  }
  .dropdown-menu.show a {
    font-size: 1.4rem;
  }
}

/* screen < 1200px */
@media (max-width: 75em) {
  .hero-modal-width {
    max-width: 60rem;
  }
  .btn_available_box {
    margin: 0 1.5rem 2rem 0;
  }
  .btn_available {
    font-size: 1.4rem;
    padding: 0.5rem 1rem;
  }
  .dropdown-menu.show {
    margin-top: -2.8rem;
  }
  .dropdown-menu.show a {
    font-size: 1.1rem;
  }
}

/* screen < 960px */
@media (max-width: 60em) {
  .hero-modal-width {
    max-width: 55rem;
  }
}

/* screen < 640px */
@media (max-width: 40em) {
  .hero-modal-width {
    max-width: 40rem;
  }
}

/* screen < 460px */
@media (max-width: 28.75em) {
  .hero-modal-width {
    max-width: 35rem;
  }
  .btn_available {
    font-size: 1.2rem;
    padding: 0.5rem 1rem;
    box-shadow: 0 0 0.4rem rgba(0, 0, 0.2);
  }
  .dropdown-menu.show {
    margin-top: -3rem;
  }
}

/* screen < 400px */
@media (max-width: 25em) {
  .hero-modal-width {
    max-width: 30rem;
  }
}
