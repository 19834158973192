.redirect_section {
  min-height: 90vh;
  padding: 13rem 10rem 0;
  font-family: 'Montserrat', sans-serif;
  background: linear-gradient(
    170deg,
    rgba(247, 241, 241, 1) 0%,
    rgba(208, 240, 241, 1) 75%,
    rgba(208, 247, 255, 1) 100%
  );
}

.error_box {
  margin: 0 auto;
  text-align: center;
}

.error_box h1 {
  font-size: 14.6rem;
  font-weight: 700;
  text-shadow: 10px 4px 10px rgba(0, 0, 0, 0.5);
}

.error_box h2 {
  text-transform: uppercase;
  font-size: 4.8rem;
  font-weight: 600;
}

.error_box p {
  font-size: 3.6rem;
  font-weight: 500;
}

.error_box button {
  margin-top: 3rem;
  padding: 1rem 2rem;
  background-color: rgb(118, 221, 225);
  border: none;
  border-radius: 100rem;
  box-shadow: 0px 0.4rem 0.8rem rgba(0, 0, 0, 0.5);
  font-size: 2.6rem;
  font-weight: 500;
  text-transform: uppercase;
  transition: all 0.2s;
}

.error_box button:hover {
  background-color: rgb(126, 245, 249);
}

.error_box button:active {
  background-color: rgb(118, 221, 225);
}

/****************
* Media Queries *
*****************/

/* screen > 2000px */
@media (min-width: 125em) {
  .error_box h1 {
    font-size: 20.6rem;
  }

  .error_box h2 {
    font-size: 5.8rem;
  }

  .error_box p {
    font-size: 4.6rem;
  }

  .error_box button {
    font-size: 3.6rem;
  }
}

/* screen > 3000px */
@media (min-width: 187.5em) {
  .error_box h1 {
    font-size: 42.6rem;
  }

  .error_box h2 {
    font-size: 8.8rem;
  }

  .error_box p {
    font-size: 6.6rem;
  }

  .error_box button {
    font-size: 5.6rem;
  }
}

/* screen > 1600px */
@media (max-width: 100em) {
  .error_box h1 {
    font-size: 10.6rem;
  }

  .error_box h2 {
    font-size: 2.8rem;
  }

  .error_box p {
    font-size: 2rem;
  }

  .error_box button {
    font-size: 1.8rem;
  }
}

/* screen < 800px */
@media (max-width: 50em) {
  .redirect_section {
    padding: 10rem 5rem 0;
  }
  .error_box h1 {
    font-size: 6.6rem;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }

  .error_box h2 {
    font-size: 2.2rem;
  }

  .error_box p {
    font-size: 1.6rem;
  }

  .error_box button {
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    font-size: 1.4rem;
  }
}

/* screen < 640px */
@media (max-width: 50em) {
  .redirect_section {
    padding: 10rem 3rem 0;
  }
}

/* screen < 560px */
@media (max-width: 35em) {
  .redirect_section {
    padding: 7.5rem 3rem 0;
  }
  .error_box h1 {
    font-size: 4.6rem;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }
  .error_box h2 {
    font-size: 2rem;
  }
  .error_box p {
    font-size: 1.4rem;
  }
  .error_box button {
    font-size: 1.2rem;
  }
}
