.joints_box {
  margin-bottom: 10rem;
}

.customize_joints_title {
  text-align: center;
  font-weight: 500;
  font-size: 2.6rem;
  text-transform: uppercase;
}

.customize_joints_options_box {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 6rem;
  padding: 2rem 6rem;
}

.customize_joints_image {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.customize_joints_image img {
  max-width: 100%;
}

.customize_joints_image > h4 {
  text-align: center;
  text-transform: uppercase;
  font-size: 1.6rem;
  font-weight: 500;
  color: rgb(76, 76, 76);
}

/****************
* Media Queries *
*****************/
/* screen > 2000px */
@media (min-width: 125em) {
  .customize_joints_image img {
    width: 60%;
  }
  .customize_joints_title {
    font-size: 3.2rem;
  }
  .customize_joints_image > h4 {
    font-size: 2.2rem;
  }
}

/* screen > 3000px */
@media (min-width: 187.5em) {
  .customize_joints_image img {
    width: 50%;
  }
  .customize_joints_title {
    font-size: 5.6rem;
  }
  .customize_joints_image > h4 {
    font-size: 3.8rem;
  }
}

/* screen < 1600px */
@media (max-width: 100em) {
  .customize_joints_title {
    font-size: 1.8rem;
  }
  .customize_joints_image > h4 {
    font-size: 1.2rem;
  }
}

/* screen < 960px */
@media (max-width: 60em) {
  .customize_joints_title {
    font-size: 1.6rem;
  }
  .customize_joints_options_box {
    gap: 5rem;
    padding: 2rem;
  }
}

/* screen < 640px */
@media (max-width: 40em) {
  .joints_box {
    margin-bottom: 5rem;
  }
  .customize_joints_image > h4 {
    font-size: 1rem;
  }
  .customize_joints_options_box {
    gap: 2rem;
    padding: 1rem 2rem;
  }
}

/* screen < 480px */
@media (max-width: 30em) {
  .customize_joints_title {
    font-size: 1.4rem;
  }
  .customize_joints_options_box {
    gap: 1.2rem;
    padding: 0 1rem;
  }
}
