.product_box {
  width: 100%;
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: 0.4rem;
}

.product_carousel.carousel.slide,
.background_carousel {
  width: 100%;
  height: 100%;
  transform: scale(1);
  opacity: 1;
  border-radius: 0;
  box-shadow: none;
}

.background_carousel {
  border-radius: 0.6rem;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.6);
  overflow: hidden;
  padding: 0;
}

.product_carousel a {
  width: 5%;
}

.product_carousel.slide {
  display: flex;
  align-items: center;
  padding: 0;
}

.product_carousel.slide img {
  height: auto;
  pointer-events: auto;
}

.carousel-item.active img {
  transition: transform 0.6s;
}

.carousel-item img {
  transform: scale(0.9);
}

.carousel-item.active img:hover,
.modal-body .carousel-item img,
.modal-body .carousel-item.active img:hover,
.background_carousel .carousel-inner .carousel-item img,
.product_carousel.carousel-item img {
  transform: scale(1);
}

/****************
* Media Queries *
*****************/
/* screen < 1080px */
@media (max-width: 67.5em) {
  .product_box {
    grid-template-columns: 5.5fr 4.5fr;
  }
}

/* screen < 840px */
@media (max-width: 52.5em) {
  .product_box {
    grid-template-columns: 1fr;
  }
}
