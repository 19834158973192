.instruction_section {
  min-height: 90vh;
  padding: 13rem 15rem 10rem;

  background: rgb(247, 241, 241);
  background: linear-gradient(
    171deg,
    rgba(247, 241, 241, 1) 0%,
    rgba(208, 240, 241, 1) 74%,
    rgba(208, 247, 255, 1) 100%
  );
}

.instruction_title {
  text-align: center;
  font-size: 7.6rem;
  font-weight: 500;
  color: #1282a2;
}

.instruction_list_box {
  margin-top: 2rem;
  background-color: red;
  padding: 3rem 5rem;
  width: 100%;

  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.4);
  border-radius: 0.4rem;
}

.instruction_list_title {
  text-align: center;
  text-transform: uppercase;
  font-size: 3.2rem;
  font-weight: 500;
  color: rgb(25, 125, 155);
}

.instruction_list_box {
  margin-top: 4rem;
}

.instruction_list_grid {
  margin-top: 3rem;
  display: grid;
  column-gap: 3rem;
  grid-template-columns: 1fr 1fr 0.8fr;
}

.instruction_list {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
}

.instruction_list li {
  font-size: 1.8rem;
  line-height: 1.8;
}

.instruction_list li a {
  transition: all 0.2s;
}

.instruction_list li a:link,
.instruction_list li a:visited {
  text-decoration: none;
  color: black;
}

.instruction_list li a:hover {
  color: rgb(25, 125, 155);
}

/****************
* Media Queries *
*****************/

/* screen > 2000px */
@media (min-width: 125em) {
  .instruction_title {
    font-size: 9.6rem;
  }
  .instruction_list_title {
    font-size: 5.2rem;
  }
  .instruction_list li {
    font-size: 2.8rem;
    line-height: 1.8;
  }
}

/* screen > 3000px */
@media (min-width: 187.5em) {
  .instruction_title {
    font-size: 11.6rem;
  }
  .instruction_list_title {
    font-size: 7.2rem;
  }
  .instruction_list li {
    font-size: 3.8rem;
  }
}

/* screen < 1600px */
@media (max-width: 100em) {
  .instruction_section {
    padding: 13rem 10rem 10rem;
  }
  .instruction_list_box {
    padding: 2rem 3rem;
  }
  .instruction_title {
    font-size: 5.6rem;
  }
  .instruction_list_title {
    font-size: 2.8rem;
  }
  .instruction_list li {
    font-size: 1.6rem;
  }
}

/* screen < 1312px */
@media (max-width: 82em) {
  .instruction_section {
    padding: 13rem 8rem 10rem;
  }
  .instruction_list_box {
    padding: 1.5rem 2rem;
  }
  .instruction_title {
    font-size: 4.8rem;
  }
  .instruction_list_title {
    font-size: 2.2rem;
  }
  .instruction_list li {
    font-size: 1.4rem;
  }
}

/* screen < 1060px */
@media (max-width: 66.25em) {
  .instruction_section {
    padding: 12rem 6rem 10rem;
  }
  .instruction_list_box {
    padding: 1rem 1.5rem;
  }
  .instruction_list_grid {
    margin-top: 1.5rem;
    grid-template-columns: 1fr 1fr 0.5fr;
    row-gap: 2rem;
    column-gap: 1.5rem;
  }
  .instruction_title {
    font-size: 3.4rem;
  }
  .instruction_list_title {
    font-size: 1.8rem;
  }
  .instruction_list li {
    font-size: 1.2rem;
  }
}

/* screen < 800px */
@media (max-width: 50em) {
  .instruction_list_grid {
    grid-template-columns: 1fr 1fr;
  }
  .instruction_list_grid ul:last-child {
    grid-row: 2/3;
    grid-column: 1/3;
  }
  .instruction_title {
    font-size: 3.4rem;
  }
  .instruction_list_title {
    font-size: 1.8rem;
  }
  .instruction_list li {
    font-size: 1.2rem;
  }
}

/* screen < 720px */
@media (max-width: 45em) {
  .instruction_section {
    padding: 11rem 5rem 7rem;
  }
  .instruction_list_grid {
    margin-top: 1rem;
    grid-template-columns: 1fr;
    row-gap: 2rem;
  }
  .instruction_list_grid ul:last-child {
    grid-row: 1/2;
    grid-column: 1/2;
  }
  .instruction_title {
    font-size: 3.2rem;
  }
}

/* screen < 448px */
@media (max-width: 28em) {
  .instruction_section {
    padding: 8rem 3rem 6rem;
  }
  .instruction_title {
    line-height: 1;
  }
  .instruction_list_box {
    margin-top: 2.5rem;
  }
  .instruction_list_grid {
    row-gap: 1rem;
  }
  .instruction_title {
    font-size: 2.8rem;
  }
  .instruction_list_title {
    font-size: 1.6rem;
  }
  .instruction_list li {
    font-size: 1rem;
  }
}
