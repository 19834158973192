.slider {
  min-height: 85vh;
  padding: 10rem 0rem;
  position: relative;
  font-family: 'Montserrat', sans-serif;

  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='1440' height='560' preserveAspectRatio='none' viewBox='0 0 1440 560'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1162%26quot%3b)' fill='none'%3e%3cpath d='M -765.3615580144706%2c247 C -669.36%2c281.6 -477.36%2c438 -285.36155801447063%2c420 C -93.36%2c402 2.64%2c177.6 194.63844198552937%2c157 C 386.64%2c136.4 482.64%2c311 674.6384419855294%2c317 C 866.64%2c323 1001.57%2c167.8 1154.6384419855294%2c187 C 1307.71%2c206.2 1382.93%2c367.8 1440%2c413' stroke='rgba(28%2c 29%2c 119%2c 1)' stroke-width='2'%3e%3c/path%3e%3cpath d='M -218.44281900274564%2c321 C -122.44%2c300.6 69.56%2c204.4 261.55718099725436%2c219 C 453.56%2c233.6 549.56%2c388.6 741.5571809972544%2c394 C 933.56%2c399.4 1029.56%2c252.8 1221.5571809972544%2c246 C 1413.56%2c239.2 1657.87%2c370.2 1701.5571809972544%2c360 C 1745.25%2c349.8 1492.31%2c228 1440%2c195' stroke='rgba(0%2c 253%2c 255%2c 1)' stroke-width='2'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1162'%3e%3crect width='1440' height='560' fill='white'%3e%3c/rect%3e%3c/mask%3e%3c/defs%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.slick-track {
  padding: 1rem 0;
}

.slick-track .slide {
  display: block;
  margin: 0 auto;
}

.slider_title {
  font-size: 7.6rem;
  font-weight: 500;
  text-align: center;
  margin-bottom: 2rem;
  color: #1282a2;
}

/* slides */
.slick-slide .slide img {
  width: 100%;
  height: auto;
  /* margin: 0 auto; */
}

.slick-slide > div {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.wall-system .slick-slide {
  transform: translate(100%, 0);
}

.slick-slide .slide {
  outline: none;
  background-color: white;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  padding: 1rem;

  transform: scale(0.5);
  transition: transform 400ms;
}

.slick-slide .slide.activeSlide {
  transform: scale(1);
  opacity: 1;
  /* margin: 4rem 0; Bug*/
}

.link_box {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 2.5rem;
}

.link_box,
.slide img {
  pointer-events: none;
}

.activeSlide > .link_box,
.activeSlide > img {
  pointer-events: auto;
}

.link_box a {
  font-weight: 500;
  transition: color 0.3s;
}

.link_box a:link,
.link_box a:visited {
  text-decoration: none;
  color: black;
}

.arrow svg:hover,
.link_box a:hover {
  color: #1282a2;
}

.slide_title {
  display: inline-block;
  margin: 2rem 0;
  justify-content: center;
  border: 1px solid black;
  padding: 1rem 2rem;
  font-size: 2rem;
  text-transform: uppercase;
  pointer-events: none;
  text-align: center;
}

.arrow {
  padding: 0 1rem;
  margin: 0 1rem;
  position: absolute;
  cursor: pointer;
  z-index: 10;
}

.arrow svg {
  transition: color 300ms;
  font-size: 4rem;
}

.next {
  right: 0%;
  top: 50%;
}

.prev {
  left: 0%;
  top: 50%;
}

/****************
* Media Queries *
*****************/

/* screen > 2000px */
@media (min-width: 125em) {
  .slider {
    padding: 16rem 0rem 10rem;
  }
  .slick-slide .slide {
    transform: scale(0.6);
  }
  .slider_title {
    font-size: 9.6rem;
  }
  .slide_title {
    font-size: 2.8rem;
  }
  .arrow svg {
    font-size: 5rem;
  }
}

/* screen > 3000px */
@media (min-width: 187.5em) {
  .slider {
    padding: 21rem 0rem;
  }
  .slider_title {
    font-size: 13.6rem;
  }
  .slide_title {
    font-size: 4.6rem;
  }
  .arrow svg {
    font-size: 7rem;
  }
}

/* screen < 1440px */
@media (max-width: 90em) {
  .slider_title {
    font-size: 6.4rem;
  }
  .slick-slide .slide {
    transform: scale(0.6);
  }
  .arrow svg {
    font-size: 3rem;
  }
}

/* screen < 1200px */
@media (max-width: 75em) {
  .slider {
    padding: 10rem 0rem 5rem;
  }
  .slider_title {
    font-size: 5.2rem;
  }
  .slick-slide .slide {
    transform: scale(0.6);
  }
  .slide_title {
    font-size: 1.4rem;
    margin: 1rem 0;
  }
  .arrow svg {
    font-size: 3rem;
  }
}

/* screen < 960px */
@media (max-width: 60em) {
  .slider {
    min-height: 90vh;
  }
  .slider_title {
    font-size: 4.6rem;
    margin-bottom: 1rem;
  }
  .slick-slide .slide.activeSlide {
    transform: scale(0.9);
    opacity: 1;
  }
  .slick-slide .slide {
    transform: scale(0.5);
    padding: 0.5rem;
  }
  .slide_title {
    font-size: 1.2rem;
    padding: 0.5rem 1rem;
    margin: 0.5rem 0;
  }
  .arrow svg {
    font-size: 2.5rem;
  }
}

/* screen < 800px */
@media (max-width: 50em) {
  .slider_title {
    font-size: 3.6rem;
  }
  .slide_title {
    font-size: 1rem;
  }
  .arrow svg {
    font-size: 2rem;
  }
}

/* screen < 640px */
@media (max-width: 40em) {
  .slider_title {
    font-size: 3.6rem;
  }
  .slick-track .slide {
    width: 60% !important;
  }
  .wall-system .slick-slide,
  .slick-slide .slide.activeSlide {
    transform: scale(1);
  }
  .slide_title {
    font-size: 1.4rem;
  }
  .arrow svg {
    font-size: 2rem;
  }
}

/* screen < 560px */
@media (max-width: 35em) {
  .slider {
    padding: 7.5rem 0rem 3rem;
  }
  .slider_title {
    font-size: 2.6rem;
  }
  .slide_title {
    font-size: 1rem;
  }
  .arrow {
    padding: 0 1rem;
    margin: 0;
  }
}

/* screen < 480px */
@media (max-width: 30em) {
  .slick-track .slide {
    width: 70% !important;
  }
}
